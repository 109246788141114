import React from 'react';
import { Avatar, Heading, majorScale, Menu, Pane, PeopleIcon, Popover, Position } from "evergreen-ui";
import { LK_URL } from "../config/config";

const Header = () => {
    return (
        <Pane
            display="flex"
            padding={majorScale(2)}
            background={"tint2"}
            elevation={1}
        >
            <Pane flex={1} alignItems="center" display="flex">
                <Heading size={600}>JugRuOrg Status</Heading>
            </Pane>
            <Pane>
                <Popover
                    position={Position.BOTTOM_LEFT}
                    content={
                        <Menu>
                            <Menu.Item icon={PeopleIcon}>
                                <a
                                    href={LK_URL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Go to LK
                                </a>
                            </Menu.Item>
                            {/*<Menu.Item icon={LogInIcon}>*/}
                            {/*    Login*/}
                            {/*</Menu.Item>*/}
                        </Menu>
                    }
                >
                    <Avatar isSolid name="Nobody" size={majorScale(5)} style={{
                        cursor: "pointer"
                    }}/>
                </Popover>
            </Pane>
        </Pane>
    );
};

export default Header;
