import React, { useEffect, useState } from 'react';
import { Badge, Heading, majorScale, Pane, Select, Spinner, Text, toaster } from "evergreen-ui";
import { HealthCheckInfo } from "../models/HealthCheckInfo";
import { getInfo } from "../actions/info";
import StatusTable from "../components/StatusTable";
import PageHeader from "../components/PageHeader";

const MainPage = () => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ data, setData ] = useState<HealthCheckInfo[]>();
    const [ error, setError ] = useState();
    const [ refreshInterval, setRefreshInterval ] = useState<number>(0);

    const getInfoCallback = () => {
        setIsLoading(true);
        getInfo()
            .then(data => {
                setData(data);
                setError(undefined);
            })
            .catch(error => {
                setError(error);
                setData(undefined);
                toaster.danger(
                    'Something went wrong. Try to refresh page.'
                )
            })
            .finally(() => setTimeout(() => setIsLoading(false), 300));
    }

    const onIntervalBoxChange = (interval: string) => {
        if (interval === 'off') {
            setRefreshInterval(0);
        } else {
            const value: number = +interval * 1000;
            setRefreshInterval(value);
        }
    }

    useEffect(() => {
        let interval: any;
        if (refreshInterval > 0) {
            interval = setInterval(getInfoCallback, refreshInterval);
        }
        return () => clearInterval(interval);
    }, [ refreshInterval ]);

    useEffect(() => {
        getInfoCallback();
    }, [])

    return (
        <Pane>
            <PageHeader text="At the this page showing status of the applications">
                <Pane display="flex"
                      marginTop={majorScale(2)}
                      alignItems="center"
                >
                    <Text size={300} marginRight={majorScale(2)}> Auto refresh</Text>
                    <Select
                        width={200}
                        onChange={event => onIntervalBoxChange(event.target.value)}>
                        <option value="off">Off</option>
                        <option value="5">5 seconds</option>
                        <option value="10">10 seconds</option>
                        <option value="30">30 seconds</option>
                        <option value="60">1 minute</option>
                    </Select>
                </Pane>
            </PageHeader>
            {
                isLoading &&
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Spinner/>
                </Pane>
            }
            {
                !isLoading && data && <StatusTable data={data}/>
            }
            {
                !isLoading && error &&
                <Pane display="flex" padding={majorScale(2)} borderRadius={3}>
                    <Pane flex={1} alignItems="center" display="flex">
                        <Heading size={600}>Error with fetch info</Heading>
                    </Pane>
                    <Pane>
                        <Badge color="red">500</Badge>
                    </Pane>
                </Pane>
            }
        </Pane>
    );
};

export default MainPage;
