import React, { FC } from 'react';
import { majorScale, Pane, Tab, TabNavigation } from "evergreen-ui";
import { Link, useLocation } from 'react-router-dom';

interface NavigationProps {
}

const Navigation: FC<NavigationProps> = () => {
    const { pathname } = useLocation();

    return (
        <Pane display="flex" padding={majorScale(2)}>
            <TabNavigation>
                {[ 'Status', 'Details', 'Incidents' ].map((tab) => (
                    <Link
                        key={tab}
                        to={`/${tab.toLowerCase()}`}
                    >
                        <Tab
                            id={tab}
                            isSelected={`/${tab.toLowerCase()}` === pathname?.toLowerCase()}
                        >
                            {tab}
                        </Tab>
                    </Link>
                ))}
            </TabNavigation>
        </Pane>
    );
};

export default Navigation;
