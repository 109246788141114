import React from 'react';
import { Link, majorScale, Pane } from "evergreen-ui";

const Footer = () => {
    return (
        <Pane
            display="flex"
            padding={majorScale(2)}
            background={"tint2"}
            elevation={1}
            marginTop="auto"
            flex="0 1 40px"
        >
            <Pane flex={1} alignItems="center" display="flex">
                <Link href="https://jugru.org" target="_blank">
                    <img src="https://jugru.org/assets/images/logo@2x.png" alt="logo" height={majorScale(4)}/>
                </Link>
            </Pane>
        </Pane>
    );
};

export default Footer;
