import React, { FC } from 'react';
import { HealthCheckInfo } from "../models/HealthCheckInfo";
import { Badge, majorScale, Pane, Table } from "evergreen-ui";
import moment from "moment";

interface StatusTableProps {
    data: HealthCheckInfo[];
}

const renderRow = (data: HealthCheckInfo) => {
    if (!data) return null;
    return (
        <Table.Row key={data.id}
                   intent={data.status === 'Unhealthy' ? "danger" : data.status === 'Degraded' ? "warning" : "none"}
        >
            <Table.TextCell>
                {data.name}
            </Table.TextCell>
            <Table.TextCell>
                {moment(data.onStateFrom, "YYYYMMDD").fromNow()}
            </Table.TextCell>
            <Table.TextCell>
                {
                    data.status === 'Healthy' ? <Badge color="green" isSolid>{data.status}</Badge> :
                        data.status === 'Unhealthy' ? <Badge color="red" isSolid>{data.status}</Badge> :
                            data.status === 'Degraded' ? <Badge color="orange" isSolid>{data.status}</Badge> :
                                <Badge color="neutral" isSolid>Unknow</Badge>
                }
            </Table.TextCell>
        </Table.Row>
    )
}

const renderHeader = (
        <Table.Head height={majorScale(5)}>
            <Table.TextHeaderCell textProps={{ size: 400 }}> Service Name </Table.TextHeaderCell>
            <Table.TextHeaderCell textProps={{ size: 400 }}>Healthy Time</Table.TextHeaderCell>
            <Table.TextHeaderCell textProps={{ size: 400 }}>Status</Table.TextHeaderCell>
        </Table.Head>
    )
;

const StatusTable: FC<StatusTableProps> = ({ data }) => {
    return (
        <Pane
            marginLeft={majorScale(2)}
            marginRight={majorScale(2)}
            elevation={1}
            borderRadius={3}
            marginBottom={majorScale(1)}
        >
            <Table>
                {renderHeader}
                <Table.Body>
                    {data.map(renderRow)}
                </Table.Body>
            </Table>
        </Pane>
    );
};

export default StatusTable;
