export const isDevEnv = process.env.REACT_APP_STAGE === 'dev';
export const isTestEnv = process.env.REACT_APP_STAGE === 'test';
export const isProdEnv = process.env.REACT_APP_STAGE === 'prod';
export const envName = isDevEnv ? 'development' : isTestEnv ? 'testing' : isProdEnv ? 'production' : 'production';

export const RING_URL =
    isDevEnv ? 'https://dev-ringostar.jugru.team/' :
        isTestEnv ? 'https://test-ringostar.jugru.team/' :
            isProdEnv ? 'https://ringostar.jugru.team/' :
                "https://dev-ringostar.jugru.team/";

export const MAIN_URL =
    isDevEnv ? 'https://services-dev.jugru.team/online/incidents/status-backend' :
        isTestEnv ? 'https://services-test.jugru.team/online/incidents/status-backend' :
            isProdEnv ? 'https://services.jugru.team/online/incidents/status-backend' :
                "";

export const INFO_URL = `${MAIN_URL}/api/v1/healthchecks`;
export const DETAILS_URL = `${MAIN_URL}/health-checks-status#/healthchecks`;
export const LK_URL = "https://my.jugru.org";
