import React, { FC } from 'react';
import { Heading, majorScale, Pane } from "evergreen-ui";

interface PageHeaderProps {
    text: string;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
    return (
        <Pane
            display="flex"
            padding={majorScale(2)}
            flexWrap="wrap"
            marginTop="-5px"
            justifyContent="space-between"
        >
            <Pane
                alignItems="center"
                marginTop={majorScale(2)}
                display="flex"
                minHeight={majorScale(4)}
            >
                <Heading size={500}>{props.text}</Heading>
            </Pane>
            {props.children}
        </Pane>
    );
};

export default PageHeader;
