import React from 'react';
import { Alert, Pane } from "evergreen-ui";
import PageHeader from "../components/PageHeader";

const IncidentsPage = () => {
    return (
        <Pane>
            <PageHeader text="At the this page showing history of systems incidents"/>
            <Alert
                appearance="card"
                intent="success"
                title="There are no incidents"
                marginBottom={32}
            />
        </Pane>
    );
};

export default IncidentsPage;
