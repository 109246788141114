import { SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

export enum AnalyticsActions {
    STATUS_OPENED = 'STATUS_OPENED',
}

export const saveAnalytic = (analyticService: SaveAnalyticsService, action: AnalyticsActions) => {
    if (analyticService) {
        setTimeout(() => analyticService.save({
            action,
        }), 100);
    }
};
