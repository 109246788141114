import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import MainPage from "./MainPage";
import Navigation from "../components/Navigation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { majorScale, Pane } from "evergreen-ui";
import { DETAILS_URL, RING_URL } from "../config/config";
import { runBootstrap, SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";
import { AnalyticsActions, saveAnalytic } from "../actions/ring";
import Footer from "../components/Footer";
import IncidentsPage from "./IncidentsPage";

const App = () => {
    const [ analyticsRingClientServices ] = useState<SaveAnalyticsService>(() => {
        const { saveAnalytics: saveAnalyticsService } = runBootstrap({
            collectorServiceUrl: RING_URL,
        }).services;

        return saveAnalyticsService;
    });

    useEffect(() => {
        saveAnalytic(analyticsRingClientServices, AnalyticsActions.STATUS_OPENED);
    }, [ analyticsRingClientServices ]);

    return (
        <Router>
            <Pane
                display="flex"
                flexDirection="column"
                minHeight="100vh"
            >
                <Header/>
                <Navigation/>
                <Pane
                    padding={majorScale(1)}
                    elevation={1}
                    flex={1}
                >
                    <Switch>
                        <Route exact path="/">
                            <MainPage/>
                        </Route>
                        <Route path="/status">
                            <MainPage/>
                        </Route>
                        <Route path="/details">
                            <Pane width="100%">
                                <iframe
                                    style={{
                                        width: "100%",
                                        height: "70vh"
                                    }}
                                    title="Details view"
                                    src={DETAILS_URL}
                                    frameBorder="0"
                                />
                            </Pane>
                        </Route>
                        <Route path="/incidents">
                            <IncidentsPage/>
                        </Route>
                    </Switch>
                </Pane>
                <Footer/>
            </Pane>
        </Router>
    );
}

export default App;
